<template>
  <ion-page class="ion-page">
    <go-back-header :defaultHref="`/facilities/${id}`" />

    <ion-content>
      <List
        :header="'Summary'"
        :loading="$apollo.loading && !getPayoutSummary"
        :not-found-message="`No summary available`"
      >
        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col class="ion-no-padding"></ion-col>
              <ion-col class="ion-no-padding ion-text-right"><ion-label color="moto"><p>{{ $t('count') }}</p></ion-label></ion-col>
              <ion-col class="ion-no-padding ion-text-right"><ion-label color="moto"><p>{{ $t('amount') }}</p></ion-label></ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col class="ion-no-padding"><ion-label color="white"><p>{{ $t('payments') }}</p></ion-label></ion-col>
              <ion-col class="ion-no-padding ion-text-right"><ion-label color="white"><p>{{ paidCount }}</p></ion-label></ion-col>
              <ion-col class="ion-no-padding ion-text-right"><ion-label color="white"><p>{{ paidAmount|stripeAmount('eur') }}</p></ion-label></ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col class="ion-no-padding"><ion-label color="white"><p>{{ $t('refunds') }}</p></ion-label></ion-col>
              <ion-col class="ion-no-padding ion-text-right"><ion-label color="white"><p>{{ refundCount }}</p></ion-label></ion-col>
              <ion-col class="ion-no-padding ion-text-right"><ion-label color="white"><p>{{ refundAmount|stripeAmount('eur') }}</p></ion-label></ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col class="ion-no-padding"><ion-label color="white" text-wrap><p>{{ $t('transactionfee') }}</p></ion-label></ion-col>
              <ion-col class="ion-no-padding ion-text-right"><ion-label color="white"><p>{{ transactionCount }}</p></ion-label></ion-col>
              <ion-col class="ion-no-padding ion-text-right"><ion-label color="white"><p>{{ totalTransactionAmount|stripeAmount('eur') }}</p></ion-label></ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <ion-item>
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col class="ion-no-padding"><ion-label color="moto" text-wrap><p>{{ $t('total') }}</p></ion-label></ion-col>
              <ion-col class="ion-no-padding ion-text-right"></ion-col>
              <ion-col class="ion-no-padding ion-text-right"><ion-label color="white"><p>{{ totalAmount|stripeAmount('eur') }}</p></ion-label></ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </List>

      <List
        :header="'Transactions'"
        :loading="$apollo.loading && !getPayoutDetails"
        :loading-more="loadingMoreStripe"
        @loadMore="loadMoreStripe"
        :not-found-message="`No transactions yet`"
      >
        <template v-if="getPayoutDetails">
          <ion-item v-for="transaction in getPayoutDetails.data" :key="transaction.id">
            <ion-label color="white">
              <p>{{ transaction.amount|stripeAmount(transaction.currency) }}</p>
              <p>{{ transaction.available_on|moment('D MMM YYYY') }}</p>
            </ion-label>
            <ion-badge :color="payoutTypeMapping[transaction.type].color" slot="end">
              <p>{{ payoutTypeMapping[transaction.type].label }}</p>
            </ion-badge>
          </ion-item>
        </template>
      </List>
    </ion-content>
  </ion-page>
</template>

<script>
import GoBackHeader from '@/components/navigation/GoBackHeader.vue'
import { GET_PAYOUT_DETAILS } from '@/graphql/queries'
import { infiniteScrollMixin } from '@/mixins';
import { GET_PAYOUT_SUMMARY } from '../../../../graphql/queries';

const payoutTypeMapping = {
  payment: {
    label: "Payment",
    color: "moto",
  },
  payment_refund: {
    label: "Refund",
    color: "medium",
  },
  adjustment: {
    label: "Paid transaction fee",
    color: "warning"
  },
}

export default {
  props: ['id', 'payoutId'],
  components: {
    GoBackHeader
  },
  computed: {
    totalTransactionAmount() {
      return this.amount(this.getPayoutSummary?.adjustment);
    },
    transactionCount() {
      return this.getPayoutSummary?.adjustment?.length || 0;
    },
    paidCount() {
      return this.getPayoutSummary?.payment?.length || 0;
    },
    paidAmount() {
      return this.amount(this.getPayoutSummary?.payment);
    },
    refundAmount() {
      return this.amount(this.getPayoutSummary?.payment_refund);
    },
    refundCount() {
      return this.getPayoutSummary?.payment_refund?.length || 0;
    },
    totalAmount() {
      return (this.paidAmount - -this.refundAmount - -this.totalTransactionAmount);
    }
  },
  data() {
    return {
      payoutTypeMapping,
      loadingMoreStripe: true
    }
  },
  mixins: [infiniteScrollMixin],
  apollo: {
    getPayoutDetails: {
      query: GET_PAYOUT_DETAILS,
      variables() {
        return {
          facility_id: this.id,
          payout_id: this.payoutId,
          cursor: null
        }
      },
    },
    getPayoutSummary: {
      query: GET_PAYOUT_SUMMARY,
      variables() {
        return {
          facility_id: this.id,
          payout_id: this.payoutId
        }
      }
    }
  },
  methods: {
    async loadMoreStripe(event) {
      if(!this.getPayoutDetails?.has_more) {
        event.target.complete();
        this.loadingMoreStripe = false;
        return;
      }

      try {
        await this.$apollo.queries.getPayoutDetails.fetchMore({
          variables: {
            facility_id: this.id,
            payout_id: this.payoutId,
            cursor: this.getPayoutDetails?.data?.[this.getPayoutDetails?.data?.length - 1]?.id
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {

            return {
              getPayoutDetails: {
                __typename: previousResult.getPayoutDetails.__typename,
                has_more: fetchMoreResult.getPayoutDetails.has_more,
                data: [...previousResult.getPayoutDetails.data, ...fetchMoreResult.getPayoutDetails.data]
              }
            }
          }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      event.target.complete();

    },
    amount(stripeType) {
      if(!stripeType?.length) {
        return 0;
      }

      return stripeType?.reduce((acc, current) => {
        return acc + current.net;
      }, 0);
    }
  }
}
</script>
